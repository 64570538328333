import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  minHeight: 280,
  paddingTop: 60,
  display: 'flex',
  background: theme.palette.grey[900],

  [theme.breakpoints.down('tablet')]: {
    paddingTop: 39,
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 800,
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const BreadcrumbsWrapper = styled.div(({theme}) => ({
  marginBottom: 10,
}))

export const Left = styled.div(({theme}) => ({
  alignSelf: 'flex-start',
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
}))

export const Subtitle = styled(Text)(({theme}) => ({
  maxWidth: 302,
  fontSize: theme.fontSizes.md,
}))

export const AwardsWrapper = styled.div(({theme}) => ({
  [theme.breakpoints.down('tablet')]: {
    margin: '0 -15px',
  },
}))
