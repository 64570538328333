import React from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {Wrapper, Inner} from './LegalDocsContent.styles'
import ContentContainer from '../ContentContainer'

const LegalDocsContent = ({content, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <Inner>
        <MarkdownView markdown={content} options={{strikethrough: true, noHeaderId: true}}/>
      </Inner>
    </ContentContainer>
  </Wrapper>
)

LegalDocsContent.propTypes = {}
LegalDocsContent.defaultProps = {}

export default LegalDocsContent
