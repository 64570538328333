import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {HomeLink, Wrapper} from './CustomBreadcrumbs.styles'

const CustomBreadcrumbs = ({children, ...rest}) => {
  const {t, language} = useI18next()
  return (
    <Wrapper {...rest}>
      <HomeLink to={language !== 'de' ? `/${language}/` : '/'}>Home</HomeLink> ▸ News
    </Wrapper>
  )
}

CustomBreadcrumbs.propTypes = {}
CustomBreadcrumbs.defaultProps = {}

export default CustomBreadcrumbs
