import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const Inner = styled.div(({theme}) => ({
  maxWidth: 670,
  fontFamily: theme.fontFamilies.lato,

  '& h1, h2, h3, h4, h5, h6': {
    marginTop: 40,
    marginBottom: 20,
    fontFamily: theme.fontFamilies.default,
    fontSize: theme.fontSizes.title,
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: 1.2,
  },

  '& h1': {
    fontSize: theme.fontSizes.titleLg,
  },

  '& h2': {
    fontSize: theme.fontSizes.title,
  },

  '& h3': {
    fontSize: theme.fontSizes.titleXs,
  },

  '& h4': {
    fontSize: theme.fontSizes.md,
  },

  '& ul': {
    paddingLeft: 18,
  },

  '& p, li': {
    marginBottom: 18,
  },

  '& a': {
    color: 'blue',
  },
}))
