import styled from 'styled-components'
import Text from 'components/Text'
import Img from 'gatsby-image'

export const Wrapper = styled.div(({theme, transparentBg}) => ({
  paddingBottom: 28,
  maxWidth: 300,
  width: '100%',
  background: 'transparent',

  [theme.breakpoints.down('tablet')]: {
    maxWidth: 375,
    background: transparentBg ? 'transparent' : theme.palette.common.black,
  },
}))

export const TitleWrapper = styled.div(({theme}) => ({
  paddingRight: 40,
  marginBottom: 22,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('tablet')]: {
    paddingRight: 0,
  },
}))

export const Title = styled(Text)(({theme}) => ({
  marginRight: 5,
}))

export const Line = styled.div(({theme}) => ({
  maxWidth: 150,
  width: '100%',
  height: 1,
  background: theme.palette.primary.main,

  '&:first-of-type': {
    display: 'none',
  },

  [theme.breakpoints.down('tablet')]: {
    maxWidth: 100,

    '&:first-of-type': {
      display: 'block',
    },
  },
}))

export const ImagesWrapper = styled.div(({theme}) => ({
  paddingRight: 30,
  display: 'flex',
  width:'100%',
  justifyContent: 'space-between',

  [theme.breakpoints.down('tablet')]: {
    padding: 0,
    justifyContent: 'space-around',
  },
}))

export const Img1 = styled(Img)(({theme}) => ({
  maxWidth: 97,
  width: '100%',
}))

export const Img2 = styled(Img)(({theme}) => ({
  margin: '0 20px',
  maxWidth: 76,
  height: '100%',
  width: '100%',
}))

export const Img3 = styled(Img)(({theme}) => ({
  maxWidth: 54,
  width: '100%',
}))
