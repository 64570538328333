import React, {useEffect} from 'react'
import SEO from 'components/SEO'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import HeroWithoutSlider from '../components/HeroWithoutSlider'
import LegalDocsContent from '../components/LegalDocsContent'
import GeneralContacts from '../components/GeneralContacts'
import ShowMob from '../components/ShowMob'
import Header from "../components/Header";
import Layout from "../components/Layout";

export default function RemunerationPage({
  data,
  location,
  pageContext: {
    breadcrumb: {crumbs},
  },
}) {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiAboutUs.en_disabled) {
      changeLanguage('de')
    }
  }, [])

  return (
    <Layout dataLocales={data} location={location}>
      <Header dataLang={data.strapiAboutUs.en_disabled} />
      <SEO
        title={data.strapiAboutUs.seo_page_title[language]}
        description={data.strapiAboutUs.seo_page_description[language]}
      />
      <HeroWithoutSlider
        title={t('sections.hero.aboutUsTitle')}
        crumbs={crumbs}
        crumbLabel={t('sections.hero.aboutUsTitle')}
      />
      <LegalDocsContent content={data.strapiAboutUs.content[language]} />
      <ShowMob>
        <GeneralContacts />
      </ShowMob>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutUsQuery ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiAboutUs {
      en_disabled
      seo_page_title {
        de
        en
      }
      seo_page_description {
        de
        en
      }
      content {
        de
        en
      }
    }
  }
`
