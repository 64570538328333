import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Breadcrumb} from 'gatsby-plugin-breadcrumb'
import {
  InnerWrapper,
  Left,
  Title,
  Subtitle,
  Wrapper,
  BreadcrumbsWrapper,
  AwardsWrapper,
} from './HeroWithoutSlider.styles'
import ContentContainer from '../ContentContainer'
import Awards from '../Awards'
import CustomBreadcrumbs from '../CustomBreadcrumbs'

const HeroWithoutSlider = ({children, title, subtitle, crumbs, crumbLabel, category, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <InnerWrapper>
          <Left>
            <BreadcrumbsWrapper>
              {category ? (
                <CustomBreadcrumbs />
              ) : (
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" ▸ "
                  crumbLabel={crumbLabel}
                  hiddenCrumbs={language === 'de' ? ['/en'] : ['/']}
                />
              )}
            </BreadcrumbsWrapper>
            <Title content={title} size="title" component="h1" color="common.white" mb={10} />
            {subtitle && <Subtitle content={subtitle} color="common.white" />}
          </Left>
          <Awards transparentBg />
        </InnerWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

HeroWithoutSlider.propTypes = {}
HeroWithoutSlider.defaultProps = {}

export default HeroWithoutSlider
