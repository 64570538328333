import React from 'react'
// import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Line, TitleWrapper, Wrapper, Title, ImagesWrapper, Img1, Img2, Img3} from './Awards.styles'

const Awards = ({children, transparentBg, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const {img1, img2, img3} = useStaticQuery(graphql`
    query {
      img1: file(relativePath: {eq: "common/awards/logo1.png"}) {
        childImageSharp {
          fluid(maxWidth: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img2: file(relativePath: {eq: "common/awards/logo2.png"}) {
        childImageSharp {
          fluid(maxWidth: 76) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img3: file(relativePath: {eq: "common/awards/logo3.png"}) {
        childImageSharp {
          fluid(maxWidth: 76) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest} transparentBg={transparentBg}>
      <TitleWrapper>
        <Line />
        <Title content={t('awards.title')} color="grey[700]" mb={0} size="xxs" />
        <Line />
      </TitleWrapper>
      <ImagesWrapper>
        <Img1 src={img1} fluid={img1.childImageSharp.fluid} />
        <Img2 src={img2} fluid={img2.childImageSharp.fluid} />
        <Img3 src={img3} fluid={img3.childImageSharp.fluid} />
      </ImagesWrapper>
    </Wrapper>
  )
}

Awards.propTypes = {}
Awards.defaultProps = {}

export default Awards
