import styled from 'styled-components'
import {Link} from 'gatsby'

export const Wrapper = styled.div(({theme}) => ({
  color: theme.palette.grey[700],
  fontSize: theme.fontSizes.xs,
}))

export const HomeLink = styled(Link)(({theme}) => ({
  color: theme.palette.common.white,
}))
