import React from 'react'
// import PropTypes from 'prop-types'
import {Wrapper} from './ShowMob.styles'

const ShowMob = ({children, ...rest}) => {
  return (
    <Wrapper {...rest}>
      {children}
    </Wrapper>
  )
}

ShowMob.propTypes = {}
ShowMob.defaultProps = {}

export default ShowMob
